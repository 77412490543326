import dynamic from "next/dynamic";

export const EmbedReport = dynamic(
  () => import("./EmbedReport").then((m) => m.EmbedReport),
  { ssr: false }
);
export const EmbedReportVisual = dynamic(
  () => import("./EmbedVisual").then((m) => m.EmbedVisual),
  { ssr: false }
);
export const PaginatedEmbedReport = dynamic(
  () => import("./PaginatedEmbedReport").then((m) => m.PaginatedEmbedReport),
  { ssr: false }
);
export type { EmbedReportConfiguration } from "./EmbedReport";
export type { PaginatedEmbedReportConfiguration } from "./PaginatedEmbedReport";
export { EmbedReportContainer } from "./EmbedReportContainer";
