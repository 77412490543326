import { AuthenticationResult } from "@azure/msal-browser";
import { ActionStatus, FormState, IPowerBIReport, PBIEntityType } from "@definitions/index";
import { toFormState, fromErrorToFormState } from "@lib/transformationFormState";
import { buildRequest, callApiServiceAsync } from "@utils/callApiService";
import { POWERBI_REPORT_API_SERVICE_URL } from "apiServiceConst";


export const fetchPowerBIEntities = async (authResult: AuthenticationResult, callBack?: Function) => {
	try {
		const reqHeaders = await buildRequest(authResult);
		const fetchResult = await callApiServiceAsync(POWERBI_REPORT_API_SERVICE_URL, reqHeaders);
		if (!fetchResult) {
			return;
		}
		if (callBack !== undefined) {
			callBack(fetchResult);
		}
		return fetchResult;
	} catch (error) {
		console.error("Error:", error);
		throw new Error("Failed to fetch Power BI entities data.");
	}
};

export const fetchPowerBIDatasetIdsAsync = async (authResult: AuthenticationResult, callBack?: Function) => {
	try {
		const reqHeaders = await buildRequest(authResult);
		const fetchResult = await callApiServiceAsync(`${POWERBI_REPORT_API_SERVICE_URL}/datasetIDs`, reqHeaders);
		if (!fetchResult) {
			return;
		}
		if (callBack !== undefined) {
			callBack(fetchResult);
		}
		return fetchResult;
	} catch (error) {
		console.error("Error:", error);
		throw new Error("Failed to fetch Power BI dataset IDs data.");
	}
};

export const fetchPowerBIEntityIdsAsync = async (authResult: AuthenticationResult, callBack?: Function) => {
	try {
		const reqHeaders = await buildRequest(authResult);
		const fetchResult = await callApiServiceAsync(`${POWERBI_REPORT_API_SERVICE_URL}/entityIDs`, reqHeaders);
		if (!fetchResult) {
			return;
		}
		if (callBack !== undefined) {
			callBack(fetchResult);
		}
		return fetchResult;
	} catch (error) {
		console.error("Error:", error);
		throw new Error("Failed to fetch Power BI entity IDs data.");
	}
};

export const updatePowerBIEntitiesAsync = async (authResult: AuthenticationResult, prevState: FormState, reports: IPowerBIReport[]) => {
	try {
		const reqHeaders = await buildRequest(authResult, 'POST', reports);
		const result = await callApiServiceAsync(`${POWERBI_REPORT_API_SERVICE_URL}/update`, reqHeaders);
		if (!result?.success) {
			return toFormState(ActionStatus.FAIL, result?.messages[0]);
		}
		return toFormState(ActionStatus.SUCCESS, "update the Power BI reports successful");
	} catch (error) {
		return fromErrorToFormState(error);
	}
};

export const fetchPowerBIEntityByNameAndType = async (authResult: AuthenticationResult, name: string, type: PBIEntityType, callBack: Function) => {
	try {
		const reqHeaders = await buildRequest(authResult);
		const fetchResult = await callApiServiceAsync(`${POWERBI_REPORT_API_SERVICE_URL}/find?name=${name}&type=${type}`, reqHeaders);
		if (!fetchResult) {
			return;
		}
		if (callBack !== undefined) {
			callBack(fetchResult);
		}
		return fetchResult;
	} catch (error) {
		console.error("Error:", error);
		throw new Error("Failed to fetch Power BI entities data.");
	}
};

export const fetchReportVisualsByKeys = async (authResult: AuthenticationResult, keys: string[], callBack: Function) => {
	try {
		const reqHeaders = await buildRequest(authResult);
		const url = `${POWERBI_REPORT_API_SERVICE_URL}/visuals?keys=${keys.join(',')}`;
		const fetchResult = await callApiServiceAsync(url, reqHeaders);
		if (!fetchResult) {
			return;
		}
		if (callBack !== undefined) {
			callBack(fetchResult);
		}
		return fetchResult;
	} catch (error) {
		console.error("Error:", error);
		throw new Error("Failed to fetch Power BI entities data.");
	}
}

export const fetchPowerReportsAsync = async (authResult: AuthenticationResult, callBack?: Function) => {
	try {
		const reqHeaders = await buildRequest(authResult);
		const fetchResult = await callApiServiceAsync(`${POWERBI_REPORT_API_SERVICE_URL}/reports`, reqHeaders);
		if (!fetchResult) {
			return;
		}
		if (callBack !== undefined) {
			callBack(fetchResult);
		}
		return fetchResult;
	} catch (error) {
		console.error("Error:", error);
		throw new Error("Failed to fetch Power BI reports.");
	}
};