import { AccountInfo, AuthenticationResult, IPublicClientApplication } from '@azure/msal-browser';

import { createContext } from 'react';

import { GroupItem } from '@lib/definitions';
import { isTokenExpired } from '@utils/jwt';
import { getTokenAccess } from '@utils/callApiService';

class AuthenticatedContext {
    instance: IPublicClientApplication;
    apiTokenResult: AuthenticationResult;
    activeAccount: AccountInfo;
    userGroups: GroupItem[];
    isAdminRole: boolean;
    isAADTenant: boolean;
    isAADAdminRole: boolean;
    unsavedChange?: boolean;
    callbackUnSavedChange?: Function;
    callbackRedirect?: Function;
    constructor(
        instance: IPublicClientApplication,
        apiTokenResult: AuthenticationResult,
        activeAccount: AccountInfo,
        userGroups: GroupItem[],
        isAdminRole: boolean,
        isAADTenant: boolean,
        isAADAdminRole: boolean) {
        this.instance = instance;
        this.apiTokenResult = apiTokenResult;
        this.activeAccount = activeAccount;
        this.userGroups = userGroups;
        this.isAdminRole = isAdminRole;
        this.isAADTenant = isAADTenant;
        this.isAADAdminRole = isAADAdminRole;
    }

    refreshToken() {
        const accessToken = this.apiTokenResult?.accessToken;
        if (accessToken) {
            if (isTokenExpired(accessToken)) {
                getTokenAccess(this.instance, this.activeAccount, true).then((res) => {
                    if (res) {
                        this.apiTokenResult = res;
                    }
                });
            }
        };
        return this.apiTokenResult;
    }

    setUnSavedChange(unsavedChange: boolean): void {
        this.unsavedChange = unsavedChange;
        this.callbackUnSavedChange = undefined;
    }

    setCallbackUnSavedChange(callback: Function): void {
        this.callbackUnSavedChange = callback;
    }

    setCallbackRedirect(callback: Function | undefined): void {
        this.callbackRedirect = callback;
    }
}

export { AuthenticatedContext }
export const AuthorizationContext = createContext({} as AuthenticatedContext);

