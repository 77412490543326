import { ActionStatus, FormState } from "@definitions/index";
import { ZodError, ZodIssue } from "zod";

export const fromErrorToFormState = (error: unknown) => {
	if (error instanceof ZodError) {
		let errors: Record<string, string[] | undefined> = {};
		error.issues?.forEach((issue: ZodIssue) => {
			if (issue.path?.length > 0) {
				errors[`${issue.path.at(-1)}`] = [issue.message];
			}
		})
		console.error(errors);
		return toFormState(ActionStatus.FAIL, "", errors);
	} else if (error instanceof Error) {
		return toFormState(ActionStatus.FAIL, error.message);
	} else {
		return toFormState(ActionStatus.FAIL, "An unknown error occurred");
	}
};

export const toFormState = (
	key: ActionStatus,
	message: string,
	errors?: Record<string, string[] | undefined>
): FormState => {
	return {
		key,
		message,
		errors: errors ?? {},
		timestamp: Date.now(),
	};
};
