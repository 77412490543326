import { IPublicClientApplication } from "@azure/msal-browser";
import { callApiServiceAsync } from "@utils/callApiService";
import { getSilentRequest } from "authConfig";


export const defaultSilentRequest = () => {
	return getSilentRequest();
}

export const fetchPBIReportAsync = async (
	instance: IPublicClientApplication,
	reportConfig: any,
	callBack: Function
) => {

	const { tokenType, accessToken } = await instance.acquireTokenSilent(defaultSilentRequest());

	reportConfig = {
		reportId: reportConfig.id,
		workspaceId: reportConfig.workspaceId,
		datasetIds: reportConfig.datasetId, //Should use array
		paginatedReportIds: reportConfig.paginatedReportId, //Should use array
		types: reportConfig.type,
		rlsRoles: reportConfig.rlsRoles
	} as any;

	const reqHeader = {
		method: 'POST',
		headers: {
			"Content-Type": "application/json",
			"Authorization": `${tokenType} ${accessToken}`
		},
		body: JSON.stringify(reportConfig)
	} as RequestInit;

	let url = `/api/generate-token`;
	const params = await callApiServiceAsync(
		url,
		reqHeader);
	if (!params) {
		return;
	}

	callBack(params);
};

export const requestExportPBIReportAsync = async (
	instance: IPublicClientApplication,
	reportId: string,
	workspaceId: string
) => {
	const { tokenType, accessToken } = await instance.acquireTokenSilent(defaultSilentRequest());
	return await fetch(
		`/api/reports/${reportId}/request-export?workspace-id=${workspaceId}`,
		{
			headers: {
				Authorization: `${tokenType} ${accessToken}`,
			},
		}
	).then((res) => {
		if (!res.ok) {
			return Promise.resolve(undefined);
		}
		return Promise.resolve(res.json());
	});
};

export const checkExportStatusAsync = async (
	instance: IPublicClientApplication,
	reportId: string,
	workspaceId: string,
	exportId: string
) => {
	const { tokenType, accessToken } = await instance.acquireTokenSilent(defaultSilentRequest());
	return await fetch(
		`/api/reports/${reportId}/check-export-status?workspace-id=${workspaceId}&export-id=${exportId}`,
		{
			headers: {
				Authorization: `${tokenType} ${accessToken}`,
			},
		}
	).then((res) => {
		if (!res.ok) {
			return Promise.resolve(undefined);
		}
		return Promise.resolve(res.json());
	});
};

export const downloadExportedFileAsync = async (
	instance: IPublicClientApplication,
	reportId: string,
	workspaceId: string,
	exportId: string
) => {
	const { tokenType, accessToken } = await instance.acquireTokenSilent(defaultSilentRequest());
	const response = await fetch(
		`/api/reports/${reportId}/download-exported-file?workspace-id=${workspaceId}&export-id=${exportId}`,
		{
			headers: {
				Authorization: `${tokenType} ${accessToken}`,
			},
		}
	);

	if (!response.ok) {
		return Promise.resolve(undefined);
	}

	const blob = await response.blob();
	return Promise.resolve(blob);
};
