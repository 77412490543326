import { styled } from "@mui/material";
export const EmbedReportContainer = styled("div")(({
  theme
}) => ({
  minHeight: `calc(100% - 35px)`,
  height: `calc(100% - 35px)`,
  borderBottom: "solid 1px #b3b0ad"
}));
export const PartEmbedReportContainer = styled("div")(({
  theme
}) => ({
  minHeight: "100%",
  height: `100%`,
  paddingBottom: '97px',
  borderBottom: "solid 1px #b3b0ad"
}));